<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        <el-breadcrumb-item>重点企业统计</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="">
      <div class="echarts">
        <el-row class="c999 f14">
          <div class="col-4" :class="{'greenBG':val1 == 'company'}" @click="getList('company')">已登记企业<p class="mt5">
              <span class="c333 f25 fb mr5">{{data1.company_all}}</span>家
          </p>
          </div>
          <div class="col-4" :class="{'greenBG':val1 == 'company-produce'}" @click="getList('company-produce')">食品生产<p
              class="mt5"><span class="c333 f25 fb mr5">{{data1.company_all_sc}}</span>家</p>
          </div>
          <div class="col-4" :class="{'greenBG':val1 == 'company-circulate'}" @click="getList('company-circulate')">
            食品流通<p class="mt5"><span class="c333 f25 fb mr5">{{data1.company_all_lt}}</span>家</p>

          </div>
          <div class="col-4" :class="{'greenBG':val1 == 'company-catering'}" @click="getList('company-catering')">
            餐饮服务提供者<p class="mt5"><span class="c333 f25 fb mr5">{{data1.company_all_cy}}</span>家</p>
            <p class="mt3"></p>
          </div>
          <div class="col-4" :class="{'greenBG':val1 == 'company-farm-produce'}"
            @click="getList('company-farm-produce')">
            农产品生产企业<p class="mt5"><span class="c333 f25 fb mr5">{{data1.company_all_ncpsc}}</span>家</p>
          </div>
   <!--       <div class="col-4 omg" :class="{'greenBG':val1 == 'company-third-party-biz'}"
            @click="getList('company-third-party-biz')">第三方经营服务提供者<p class="mt5"><span
                class="c333 f25 fb mr5 ">{{data1.company_all_tp_jyfw}}</span>家</p>
          </div>-->
          <div class="col-4 omg" :class="{'greenBG':val1 == 'company-third-party-biz15'}"
               @click="getList('company-third-party-biz',15)">市场开办方<p class="mt5"><span
                  class="c333 f25 fb mr5 ">{{data1.company_all_tp_sckb}}</span>家</p>
          </div>
          <div class="col-4 omg" :class="{'greenBG':val1 == 'company-third-party-biz16'}"
               @click="getList('company-third-party-biz',16)">贮存服务提供者<p class="mt5"><span
                  class="c333 f25 fb mr5 ">{{data1.company_all_tp_zcfw}}</span>家</p>
          </div>
          <div class="col-4 omg" :class="{'greenBG':val1 == 'company-third-party-biz19'}"
               @click="getList('company-third-party-biz',19)">运输公司<p class="mt5"><span
                  class="c333 f25 fb mr5 ">{{data1.company_all_tp_ysgs}}</span>家</p>
          </div>
          <div class="col-4" :class="{'greenBG':val1 == 'company-third-party-unit'}"
            @click="getList('company-third-party-unit')">第三方检测单位<p class="mt5"><span
                class="c333 f25 fb mr5">{{data1.company_all_tp_jcdw}}</span>家</p>
          </div>
        </el-row>
      </div>
      <el-row class="box search mt20" :gutter="10">
        <div class="ml20 mb20 tl f14 c999">时间：<span v-if="page.search_time_bigen"
            class="c333 mr30">{{page.search_time_bigen}} 至 {{page.search_time_end}}</span><span class="mr30"
            v-else>---</span>地点：<span
            class="c333 ">{{page.province}}{{page.city}}{{page.area}}{{page.town}}{{page.vill}}</span></div>
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4">
            <el-form-item size="small">
              <el-select clearable v-model="provinceTemp" placeholder="请选择省级" @change="handleChange($event,2)"
                value-key="region_name" >
                <el-option v-for="item in provinceOptions" :key="item.region_code" :label="item.region_name"
                  :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select clearable v-model="cityTemp" placeholder="请选择市级" @change="handleChange($event,3)"
                value-key="region_name" >
                <el-option v-for="item in cityOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select clearable v-model="areaTemp" placeholder="请选择区/县" @change="handleChange($event,4)"
                value-key="region_name" >
                <el-option v-for="item in areaOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-select clearable v-model="townTemp" placeholder="请选择街道/镇" @change="handleChange($event,5)"
                value-key="region_name" >
                <el-option v-for="item in townOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item size="small">
              <el-select clearable v-model="villTemp" placeholder="请选择居委会/村" value-key="region_name"
                @change="handleChange($event,6)">
                <el-option v-for="item in villOptions" :key="item.region_code" :label="item.region_name" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="small">
              <el-input placeholder="企业名称" v-model="page1.company_name" clearable class="input-with-select"
                @clear="onSearch">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" v-if="val1 === 'company-circulate'">
            <el-form-item size="small">
              <!-- <el-input  v-model="page1.market_company_name"  class="input-with-select" @clear="onSearch">
            </el-input> -->
              <el-select placeholder="所属市场" clearable v-model="page1.market_company_name" filterable @clear="onSearch"
                @change="onSearch">
                <el-option v-for="(item,index) in bizList" :key="index" :label="item.company_name"
                  :value="item.company_name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="small">
              <el-button type="primary" @click="onSearch" class="general_bgc general_border cfff">查询</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>

      <div class="box list  mt20">
        <div class="title f15">
          <p><span class="triangle-right fl ml10 mr10"></span>统计明细表</p>
          <!-- <el-button type="primary" v-if="loading==false" size="small" @click="DownExcel">导出数据</el-button>
          <el-button v-else-if="loading==true" type="info" disabled size="small">导出数据</el-button> -->
        </div>
        <div class="pannerArea mt15">
          <ul>
            <el-row class="w area-title tl pl10 pr10 flex">
              <el-col v-if="val1!='company'" :span="1"><span class="iconAdd el-icon-arrow-right c-trans "></span>
              </el-col>
              <el-col :span="5" class="omg">企业名称</el-col>
              <el-col :span="5">统一社会信用代码</el-col>
              <el-col :span="4">地址</el-col>
              <el-col :span="3">负责人</el-col>
              <el-col :span="4">登记时间</el-col>
              <el-col :span="3" v-if="val1 === 'company-circulate'">所属市场</el-col>
              <!-- <span class="iconAdd el-icon-arrow-right c-trans "></span> -->
            </el-row>
            <div v-if="tableData.length>0">
              <li v-for="(item,index) in tableData" :key="index" class="">
                <div :class="{'greenBG':currentIdx == item.company_id}" @click.stop="showToggle(item)">
                  <el-row class="w tl pl10 pr10 flex">
                    <el-col v-if="val1!='company'" :span="1"><span class="iconAdd el-icon-arrow-right"
                        v-if="currentIdx != item.company_id"></span>
                      <span class="iconReduce el-icon-arrow-down" v-if="currentIdx == item.company_id"></span>
                    </el-col>
                    <el-col :span="5" class=" c5f omg">
                      <div>{{item.company_name}}</div>
                    </el-col>
                    <el-col :span="5" class=" omg">{{item.credit_code}}</el-col>
                    <el-col :span="4" class=" omg">{{item.province}}{{item.city}}{{item.area}}{{item.town}}{{item.vill}}
                    </el-col>
                    <el-col :span="3" v-if="val1 == 'company'">{{item.user_name}}</el-col>
                    <el-col :span="3" v-else>{{item.legal_person_name}}</el-col>
                    <el-col :span="4" class=" omg">{{item.add_time}}</el-col>
                    <el-col :span="3" v-if="val1 === 'company-circulate'">{{item.market_company_name}}</el-col>

                  </el-row>
                </div>
                <el-form ref="form" v-if="currentIdx == item.company_id">
                  <el-col :span="9" class="tl mb20" :offset="1">
                    <span class="left">类型：</span><span class="c333">{{list1.company_tag_id | statusFilter}}</span>
                  </el-col>
                  <el-col :span="9" class="tl mb20" :offset="1">
                    <span class="left">法定代表人：</span><span class="c333">{{list1.legal_person_name}}</span>
                  </el-col>
                  <el-col :span="9" class="tl mb20" :offset="1">
                    <span class="left">成立资本：</span><span class="c333">无</span>
                  </el-col>
                  <el-col :span="9" class="tl mb20" :offset="1">
                    <span class="left">营业执照：</span><span class="c333">
                      <el-button v-if="showBtn" size="small" type="primary"
                        @click="handlePictureCardPreview(list1.accessory_list,0)">查看</el-button><span v-else>无</span>
                    </span>
                  </el-col>
                  <el-col :span="9" class="tl mb20" :offset="1">
                    <span class="left">成立日期：</span><span class="c333">{{list1.record_time}}</span>
                  </el-col>
                  <el-col :span="9" class="tl mb20" :offset="1">
                    <span class="left">有效期至：</span><span class="c333">{{list1.unuseful_time}}</span>
                  </el-col>
                  <el-col :span="9" class="tl mb20" :offset="1">
                    <span class="left">身份证正面：</span><span class="c333">
                      <el-button v-if="showBtn" size="small" type="primary"
                        @click="handlePictureCardPreview(list1.accessory_list,1)">查看</el-button><span v-else>无</span>
                    </span>

                  </el-col>
                  <el-col :span="9" class="tl mb20" :offset="1">
                    <span class="left">经营范围：</span><span class="c333">{{list1.business_names}}</span>
                  </el-col>
                </el-form>
              </li>
            </div>
            <el-row v-else class="w tc pl10 mb20 flex text-no">
              <el-col :span="24" class="p20">
                暂无数据
              </el-col>
            </el-row>
          </ul>

        </div>

        <div class="i-page fr disib mt20">
          <el-pagination background :page-size='10' layout="total,prev, pager, next" :total="total"
            :current-page="currentPage" @current-change='currentChange'>
          </el-pagination>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { mapGetters } from "vuex";
import { imageUrl } from '../../../config/index'
import { local } from "../../../util/util";

let option;
export default {
  filters: {
    statusFilter (status) {
      const statusMap = {
        2: '四川省小经营店登记证（流通）',
        5: '食品经营许可证（流通）',
        13: '农产品生产企业',
        1: '四川省小作坊登记证',
        18: '食品经营许可证（生产）',
        15: '市场开办方',
        16: '贮存服务提供者',
        3: '食品经营许可（餐饮）',
        4: '四川省小经营店登记证（餐饮）',
        19: '运输公司',
        17: '第三方检测单位',
      }
      return statusMap[status]
    },

  },
  data () {
    return {
      imageUrl: imageUrl,
      page: {
        down_name: '',
        province: "",
        city: "",
        area: "",
        town: "",
        vill: "",
        search_time_bigen: "",
        search_time_end: '',
        company_name: ''
      },
      page1: {
        pager_offset: "0",
        company_name: '',
        department_name: '',
        expire_state: '',
        filing_state: '',
        business_state: '',
        business_type: '',
        statistics_filing_state: "'2,3,4'",
        province: "",
        city: "",
        area: "",
        town: "",
        vill: "",
        search_time_bigen: "",
        search_time_end: '',
        company_tag_id:'',
      },
        province: "",
        city: "",
        area: "",
        town: "",
        vill: "",
      provinceTemp: "",
      cityTemp: "",
      areaTemp: "",
      townTemp: "",
      villTemp: "",
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      tableData: [],
      bizList: [],
      userData: {},
      data1: '',
      timer: '',
      currentPage: 1,
      total: 0,
      currentIdx: '',
      val1: 'company',
      val2:'company',
      loading: false,
      expandRow: '',
      dialogVisible: false,
      dialogImageUrl: '',
      list1: '',
      showBtn: false,
      pickerOptionsStart: {
        disabledDate: time => {
          if (this.page.search_time_end) {
            return time.getTime() > new Date(this.page.search_time_end).getTime()
          } else { return time.getTime() > Date.now() }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          return time.getTime() > Date.now()
        }
      },
    };
  },
  computed: {

  },
  mounted () {
    this.userData = local.get("user");
    this.handleUserLevel(this.userData);
    this.init(this.page);
    this.getList('company')
  },
  methods: {
      initArea() {
          let url = "/pc/department/"+this.userData.user_id;
          //预加载监管部门区域
          axios.get(url).then(res=>{
              res = res.data.department;
              let i=0;
              if (res.length>0){
                  let  level = res[0].level;
                  for(let j=0;j<res.length;j++){
                      if (level>res[j].level)
                          i = j;
                  }
                  this.province = res[i].province;
                  this.city = res[i].city;
                  this.area = res[i].area;
                  this.provinceTemp = res[i].province;
                  this.cityTemp = res[i].city;
                  this.areaTemp = res[i].area;
                  this.townTemp = res[i].town;
                  this.page.province =res[i].province;
                  this.page.city = res[i].city;
                  this.page.area = res[i].area;
                  this.userData.province = this.provinceTemp;
                  this.userData.city = this.cityTemp;
                  this.userData.area = this.areaTemp;

              }
          })
      },
   handleSelectionChange(data) {
      // this.prepareDeleteData = data
    },
    getbizList () {
      var that = this;
      axios.get('/pc/company/list/state', { filing_state: '3', company_type_id: '15' }).then((v) => {
        this.bizList = v.data.company_list
      })
    },
    showToggle (row) {
      var that = this
      this.list1 = ''
      if (that.val2 != 'company') {
        if (this.currentIdx == row.company_id) return this.currentIdx = -1;
        this.currentIdx = row.company_id;
        if (this.currentIdx == row.company_id) {
          axios.get('/pc/' + that.val2 + '/single', { company_id: row.company_id.toString() }).then((v) => {
            if (that.val2 === 'company-produce') {
              that.list1 = v.data.companyProduce;
            } else if (that.val2 === 'company-circulate') {
              that.list1 = v.data.companyCirculate;
            } else if (that.val2 === 'company-catering') {
              that.list1 = v.data.companyCatering;
            } else if (that.val2 === 'company-farm-produce') {
              that.list1 = v.data.companyFarmProduce;
            } else if (that.val2 === 'company-third-party-biz') {
              that.list1 = v.data.companyThirdPartyBiz;
            } else if (that.val2 === 'company-third-party-unit') {
              that.list1 = v.data.companyThirdPartyUnit;
            }
            if (that.list1.accessory_list.length > 0) {
              this.showBtn = true
            } else {
              this.showBtn = false
            }
            that.$forceUpdate()
            // this.$set(this.list1,that.list1)

          }).catch(() => { })
        }
      }


    },

    handlePictureCardPreview (file, index) {
      if (file.length > 0) {
        this.dialogImageUrl = file[index].accessory_url;
        this.dialogVisible = true;
      } else {
        this.$message.error('没有上传证件');
        return false
      }

    },
    init (params) {
      if (this.userData.province) { this.page1.province = this.userData.province; }
      if (this.userData.city) { this.page1.city = this.userData.city; }
      if (this.userData.area) { this.page1.area = this.userData.area; }
      if (this.userData.town) { this.page1.town = this.userData.town; }
      if (this.userData.vill) { this.page1.vill = this.userData.vill; }
      axios.get('/pc/data-statistics/company-record-sign', params).then((v) => {
        this.data1 = v.data
      })

    },
    getList (val,tag_id) {
      var that = this;
      that.currentPage = 1
      that.val1 = val
      that.val2 = val;
      that.page1.pager_offset = '0'
      if (tag_id==15){
        that.val1 = val+15
        that.page1.company_tag_id = String(tag_id);
        that.getData(val);
      }else if(tag_id ==16){
        that.val1 = val+16
        that.page1.company_tag_id = String(tag_id);
        that.getData(val);
      }else if(tag_id==19){
        that.val1 = val+19
        that.page1.company_tag_id = String(tag_id);
        that.getData(val);
      }
      else {
        that.val1 = val
        that.getData(val)
      }

    },
    getData (params) {
      var that = this;
      axios.get('/pc/' + params + '/signCompanyList', this.page1).then((v) => {
        that.tableData = v.data.company_list;
        that.total = v.data.pager_count
        if (this.page1.company_tag_id==='15'){
          that.tableData = v.data.company_list_sckb;
          that.total = v.data.pager_count_sckb
        }
       else if (this.page1.company_tag_id==='16'){
          that.tableData = v.data.company_list_zcfw;
          that.total = v.data.pager_count_zcfw
        } else if (this.page1.company_tag_id==='19'){
          that.tableData = v.data.company_list_ysgs;
          that.total = v.data.pager_count_ysgs
        }else {
          that.tableData = v.data.company_list;
          that.total = v.data.pager_count
        }
      }).catch(() => { })
      if (that.val1 === 'company-circulate') {
        this.getbizList()
      }
    },
    // 分页
    currentChange (page) {
      var that = this;
      that.currentPage = page;

      that.page1.pager_offset = String((page - 1) * 10)
      that.getData(that.val2)
    },
    async handleUserLevel (userData) {
      if (parseInt(userData.user_type) > 0) {
        this.provinceOptions = await this.fetchRegionData(1);
        let province = this.provinceOptions.find(
          item => item.region_name == userData.province
        );
        this.provinceTemp = province;
        if (parseInt(userData.user_type) >= 1 && userData.province != null && userData.province != '') {
          this.cityOptions = await this.fetchRegionData(2, province.region_code);
          let city = this.cityOptions.find(item => item.region_name == userData.city);
          if (parseInt(userData.user_type) >= 2 && userData.user_city != null) {
            this.areaOptions = await this.fetchRegionData(3, city.region_code);
            let area = this.areaOptions.find(
              item => item.region_name == userData.area
            );
            this.cityTemp = city;
            if (parseInt(userData.user_type) >= 3 && userData.area != null) {
              this.townOptions = await this.fetchRegionData(4, area.region_code);
              let town = this.townOptions.find(
                item => item.region_name == userData.town
              );
              this.areaTemp = area;
              if (parseInt(userData.user_type) >= 4 && userData.town != null) {
                this.villOptions = await this.fetchRegionData(5, town.region_code);
                let vill = this.villOptions.find(
                  item => item.region_name == userData.vill
                );
                this.townTemp = town;
                if (parseInt(userData.user_type) >= 5 && userData.vill != null) {
                  this.page1.vill = vill;
                }
              }
            }
          }
        }
      }
    },
    fetchRegionData (type, region_high_code) {

      let region_type = type.toString();

      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then(response => {
            resolve(response.data.region_list);
          })
          .catch(err => {
            reject(err);
          });
      });
      //  }

    },
    // 搜索
    onSearch () {
      if (this.page1.search_time_end == null) { this.page1.search_time_end = ''; }
      if (this.page1.search_time_bigen == null) { this.page1.search_time_bigen = ''; }
      // alert(this.val1);
      if (this.val1.indexOf(15)!=-1){
        this.val1 = this.val1.replace("15","");
      }else if(this.val1.indexOf(16) !=-1){
        this.val1 = this.val1.replace("16","")
      }else if(this.val1.indexOf(19)!=-1){
        this.val1 = this.val1.replace("19","")
      }
      // alert(this.val1);
      this.getData(this.val1)
    },
    DownExcel () {
      this.loading = true;
      this.timer = setInterval(() => { this.check("export_companyrecordlist_search"); }, 2000);
      if (this.page.search_time_end == null) { this.page.search_time_end = ''; }
      if (this.page.search_time_bigen == null) { this.page.search_time_bigen = ''; }
      let statename
      if (this.page.search_time_end != '' && this.page.search_time_bigen != '') {
        statename = this.page.search_time_bigen + '至' + this.page.search_time_end + '企业统计明细表';
      } else {
        statename = '企业统计明细表';
      }
      this.page.down_name = statename
      axios.fileget('/pc/report-excel/export_company_record_list', this.page).then((v) => { })
    },
    check (d_name) {
      axios.get('/pc/report-excel/check', { down_name: d_name }).then((v) => {
        if (v == true) {
          clearInterval(this.timer);
          this.loading = false;
        }
      })
    },
    async handleChange (item, type) {
      let regionData;
      if (item.region_code == undefined) {
        regionData = ''
      } else {
        regionData = await this.fetchRegionData(type, item.region_code);
      }

      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.page1.province = item.region_name;
          this.page1.city = "";
          this.page1.area = "";
          this.page1.town = "";
          this.page1.vill = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.townOptions = [];
          this.villOptions = [];
          this.page1.city = item.region_name;
          this.page1.area = "";
          this.page1.town = "";
          this.page1.vill = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.villOptions = [];
          this.page1.area = item.region_name;
          this.page1.town = "";
          this.page1.vill = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.page1.town = item.region_name;
          this.page1.vill = "";
          break;
        case 6:
          this.page1.vill = item.region_name;
          break;
      }
      this.onSearch()
    },
    change (index, val) {
      var that = this;
      if (val == null) val = '';
      if (index == 1) { that.page1.search_time_bigen = val }
      if (index == 2) { that.page1.search_time_end = val }
      this.onSearch()
    },

  },

};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .echarts {
    .greenBG {
      background-color: #f5f5f5;
    }
    .col-4 {
      float: left;
      width: 14.285%;
      border: 1px solid #ccc;
      border-right: none;
      padding: 10px;
    }
    .col-4:last-child {
      border-right: 1px solid #ccc;
    }
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .triangle-right {
      display: inline-block;
      vertical-align: bottom;
      width: 0;
      height: 0;
      border-width: 10px 0px 10px 16px;
      border-style: solid;
      border-color: transparent transparent transparent #ff992c;
    }
  }
  .pannerArea {
    ul {
      .area-title {
        .el-col {
          height: 60px;
          border-right: 1px solid #e6ecf6;
          line-height: 60px;
          padding-left: 10px;
        }
        .el-col:last-child {
          border-right: none;
        }
        background-color: #f5f5f5;
        border: 1px solid #e6ecf6;
        display: flex;
        align-items: center;
      }
      li {
        list-style: none;
        .el-row {
          .el-col {
            height: 60px;
            border-right: 1px solid #e6ecf6;
            line-height: 60px;
            padding-left: 10px;
          }
          .el-col:last-child {
            border-right: none;
          }
          background: #fff;
          border: 1px solid #e6ecf6;
          cursor: pointer;
          font-size: 14px;
          border-top: none;
          display: flex;
          align-items: center;
        }
        .el-form {
          padding-top: 10px;
          display: inline-block;
          width: 100%;
          border: 1px solid #f5f5f5;
        }
      }
    }
  }
  .text-no {
    border: 1px solid #f5f5f5;
    border-top: none;
  }
  /deep/ .el-table--border {
    border-top: none;
  }
  /deep/ .el-table .el-form-item {
    margin-bottom: 0px;
  }
  /deep/ .el-checkbox__label {
    display: none;
  }
}
</style>
